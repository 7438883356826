import netteAjax from '@module/ajax/nette';

const registry = {
	dynamicQuerySelectorAll: [],
	dynamicQuerySelectorAllCallbacks: [],
};

/**
 * @param {NodeListOf|HTMLElement[]} elements
 * @param {function(el: HTMLElement):void} callback
 */
function dynamicQuerySelectorAll(elements, callback) {
	elements.forEach((el) => {
		callback(el);
	});
}

const elements = {

	isDescendant(parent, child) {
		do {
			if (child === parent) {
				return true;
			}

			child = child.parentNode;
		} while (child);

		return false;
	},

	// TODO: not used
	onClickOutside(element, callback, once = false) {
		const clickEvent = event => {
			let target = event.target;

			if (!this.isDescendant(element, target)) {
				if (once) {
					document.removeEventListener('click', clickEvent);
				}

				callback(event);
			}
		};

		document.addEventListener('click', clickEvent);
	},

	createElementFromTemplate(html) {
		const template = document.createElement('template');

		template.innerHTML = html;

		return template.content.firstChild;
	},

	/**
	 * @param {HTMLElement} el
	 * @param {string|string[]} name
	 */
	toggleClass(el, name) {
		if (!Array.isArray(name)) {
			name = [name];
		}

		name.forEach(item => {
			if (el.classList.contains(item)) {
				el.classList.remove(item);
			} else {
				el.classList.add(item);
			}
		});

	},

	/**
	 * @param {string} typeEvent
	 * @param {string} selector
	 * @param {function(el: HTMLElement, event: Event): void|boolean} callback
	 * @param {any} context
	 */
	delegateEvent(typeEvent, selector, callback, context = null, options = {}) {
		if (context === null) {
			context = document;
		}

		context.addEventListener(typeEvent, (event) => {
			const el = event.target.closest(selector);
			if (el) {
				if (callback(el, event) === false) {
					return false;
				}
			}
		}, options);
	},

	/**
	 * Evaluates on init and after snippet
	 *
	 * @param {string} selector
	 * @param {function(el: HTMLElement):void} callback
	 */
	dynamicQuerySelectorAll(selector, callback) {
		dynamicQuerySelectorAll(document.querySelectorAll(selector), callback);

		registry.dynamicQuerySelectorAll.push([selector, callback]);
	},

	/**
	 * Evaluates on init and after snippet
	 *
	 * @param {function(root: HTMLElement):void} callback
	 */
	dynamicQuerySelectorAllCallback(callback) {
		callback(document.body);

		registry.dynamicQuerySelectorAllCallbacks.push(callback);
	}

};

netteAjax.extension({
	afterSnippet(el) {
		registry.dynamicQuerySelectorAll.forEach(([selector, callback]) => {
			if (el.matches(selector)) {
				dynamicQuerySelectorAll([el], callback);
			}

			dynamicQuerySelectorAll(el.querySelectorAll(selector), callback);
		});

		registry.dynamicQuerySelectorAllCallbacks.forEach(callback => {
			callback(el);
		});
	}
});

export default elements;

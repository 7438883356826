import elements from '@module/elements';

elements.delegateEvent('click', '[data-copy-clipboard]', (el, event) => {
	event.preventDefault();

	const targetSelector = el.getAttribute('data-copy-clipboard');
	const target = document.querySelector(targetSelector);
	if (target) {
		target.select();

		document.execCommand('copy');
	}

	return false;
});

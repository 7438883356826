import "./built-in/autoload";

// @extensions
import '@extensions/js-tabs';
import '@extensions/js-date-control';
import '@extensions/js-ui-block-hideable';
import '@extensions/js-dropdown';
import '@extensions/js-slider';
import '@extensions/js-upload-preview';

import '@extensions/data-headroom';
import '@extensions/data-copy-clipboard';
import '@extensions/data-async-component';
import '@extensions/data-async-link';
import '@extensions/data-tooltip';
import '@extensions/data-step';
import '@extensions/data-file-upload';
import '@extensions/data-chars-left';
import '@extensions/data-truncate';
import '@extensions/data-nette-rules';
import '@extensions/data-toggle';
import '@extensions/data-maxlen-countdown';
import '@extensions/data-confirm';
import '@extensions/data-truncate-height';
import '@extensions/data-autosave';
import '@extensions/data-modal';

// lightbox
import 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';

// src
import "./src/autoload";

// vue
import './vue/autoload';

// stimulus
import './stimulus/autoload';

// initialize
import netteAjax from '@module/ajax/nette';

netteAjax.initialize();

import { Controller } from 'stimulus';
import { useDebounce, useClickOutside } from "stimulus-use";

export default class extends Controller {
	static values = {
		apiLink: String,
	};
	static targets = ['results', 'inside'];
	static debounces = ['search'];

	opened = false;

	connect() {
		useDebounce(this);
		useClickOutside(this, {
			element: this.insideTarget,
		});

		window.addEventListener('keydown', event => {
			if (this.opened && event.key === 'Escape') {
				this.close();
			}
		});
	}

	clickOutside() {
		if (!this.opened) {
			return;
		}

		this.close();
	}

	onSearchInput(event) {
		this.search(event.currentTarget.value);
	}

	async search(term) {
		this.opened = true;

		const response = await fetch(this.apiLinkValue.replace('PLACEHOLDER', term));
		this.resultsTarget.innerHTML = await response.text();
	}

	close() {
		this.opened = false;

		this.resultsTarget.innerHTML = '';
	}

}

import elements from "@module/elements";
import dropdownFactory from "@extensions/js-dropdown/factory";

elements.dynamicQuerySelectorAll('.js-dropdown', el => {
	const dropdown = el.closest('.dropdown');
	if (!dropdown) {
		throw new Error('.js-dropdown must be inside .dropdown');
	}

	dropdownFactory(el, dropdown);
});

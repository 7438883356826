import Headroom from 'headroom.js';

document.querySelectorAll('[data-headroom]').forEach(el => {
	const isMenu = el.classList.contains('__menu');

	const headroom = new Headroom(el, {
		'offset': 210,
		'tolerance': 5,
		'classes': {
			'initial': 'animated',
			'pinned': 'slideDown',
			'unpinned': 'slideUp'
		},
		onPin() {
			if (isMenu) {
				document.body.classList.add('menu-pinned');
			}
		},
		onUnpin() {
			if (isMenu) {
				document.body.classList.remove('menu-pinned');
			}
		}
	});

	if (isMenu) {
		document.body.classList.add('menu-pinned');
	}

	headroom.init();
});

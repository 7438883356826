<template>
	<div class="score-container __score hover-effect">
		<span class="score-handler" v-for="i in [1,2,3,4,5]">
			<i :class="['fa-star', selected >= i ? 'fas' : 'far']" @click="selected = i"></i>
		</span>
		<span>
			({{selectedText}})
		</span>
	</div>
</template>

<script>
export default {

	startup(el) {
		el.style.display = 'none';
		this._el = el;
	},

	data() {
		return {
			selected: null,
		}
	},

	computed: {
		selectedText() {
			return this.selected ?? 'zvolte hodnotu';
		}
	},

	watch: {
		selected(value) {
			this._el.value = value;
		}
	}

};
</script>

export default class FormStateButtonExtension {

	text = 'Sending...';
	classes = 'disabled';
	disabledAttribute = true;

	constructor(naja, text = 'Sending...', classes = 'disabled', disabledAttribute = true) {
		this.text = text;
		this.classes = classes;
		this.disabledAttribute = disabledAttribute;

		naja.addEventListener('interaction', this.interaction.bind(this));
		naja.addEventListener('before', this.before.bind(this));
		naja.addEventListener('complete', this.complete.bind(this));
	}

	interaction({element, options}) {
		if (element.tagName === 'FORM') {
			let button = element.querySelector('input[type="submit"]');

			if (!button) {
				button = element.querySelector('button[type="submit"]');
				if (!button) {
					return;
				}
			}

			element = button;
		} else if (element.getAttribute('type') !== 'submit') {
			if (element.classList.contains('js-form-state')) {
				options._submitEl = element;
			}

			return;
		}

		if (element.closest('form').classList.contains('js-form-state')) {
			options._submitEl = element;
		}
	}

	before({options}) {
		/** @type {HTMLElement} */
		const el = options._submitEl;
		if (!el) {
			return;
		}

		el.setAttribute('prev-value', this.getContent(el));
		this.changeContent(el, this.text);

		if (this.classes !== null) {
			el.classList.add(this.classes);
		}
		if (this.disabledAttribute && (this.isButton(el) || this.isInput(el))) {
			el.setAttribute('disabled', '');
		}
	}

	/**
	 * @param {HTMLElement} el
	 */
	isInput(el) {
		return el.tagName === 'INPUT';
	}

	/**
	 * @param {HTMLElement} el
	 */
	isButton(el) {
		return el.tagName === 'BUTTON';
	}

	/**
	 * @param {HTMLElement} el
	 * @param {string} value
	 */
	changeContent(el, value) {
		if (this.isInput(el)) {
			el.value = value;
		} else {
			el.textContent = value;
		}
	}

	/**
	 * @param {HTMLElement} el
	 * @return {string}
	 */
	getContent(el) {
		if (this.isInput(el)) {
			return el.value;
		} else {
			return el.textContent;
		}
	}

	complete({options}) {
		const el = options._submitEl;
		if (!el || !el.hasAttribute('prev-value')) {
			return;
		}

		this.changeContent(el, el.getAttribute('prev-value'));
		el.removeAttribute('prev-value');

		if (this.classes !== null) {
			el.classList.remove(this.classes);
		}
		if (this.disabledAttribute && (this.isButton(el) || this.isInput(el))) {
			el.removeAttribute('disabled');
		}
	}


}

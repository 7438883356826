import elements from '@module/elements';
import netteAjax from '@module/ajax/nette';

elements.delegateEvent('click', '[data-async-component]', (el, event) => {
	event.preventDefault();

	if (el.hasAttribute('async-component-init')) {
		return;
	}


	el.setAttribute('async-component-init', 'true');

	netteAjax.makeRequest('GET', el.dataset.asyncComponent, null, {
		history: false,
	});
});

import elements from '@module/elements';

function truncate(str, length = null, ending = null) {
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = '...';
	}
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	} else {
		return str;
	}
}

const templates = {

	more(text) {
		return elements.createElementFromTemplate(`<a href="#" class="pointer js-truncate-inline-more"> ${text}</a>`);
	},

	less(text) {
		return elements.createElementFromTemplate(`<a href="#" class="pointer js-truncate-inline-less hidden"> ${text}</a>`);
	},

};

elements.dynamicQuerySelectorAll('[data-truncate]', el => {
	const maxLength = parseInt(el.dataset.truncate);
	const textLength = el.textContent.length;
	const classes = el.dataset.truncateClass;
	const original = el.innerHTML;

	if (textLength < maxLength) {
		return;
	}

	const truncated = truncate(original, maxLength);

	const moreEl = templates.more('Více');
	const lessEl = templates.less('Méně');

	if (classes !== undefined) {
		moreEl.classList.add(classes);
		lessEl.classList.add(classes);
	}

	el.innerHTML = truncated;

	el.parentNode.insertBefore(moreEl, el.nextSibling);
	el.parentNode.insertBefore(lessEl, el.nextSibling);

	moreEl.addEventListener('click', (e) => {
		e.preventDefault();

		el.innerHTML = original;

		elements.toggleClass(moreEl, 'hidden');
		elements.toggleClass(lessEl, 'hidden');
	});

	lessEl.addEventListener('click', (e) => {
		e.preventDefault();

		el.innerHTML = truncated;

		elements.toggleClass(moreEl, 'hidden');
		elements.toggleClass(lessEl, 'hidden');
	});
});

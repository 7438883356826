if (window.storyEdit !== true) {
	document.querySelectorAll('.post-content figure.image').forEach(el => {
		const img = el.querySelector('img');
		if (!img) {
			return;
		}

		if (img.parentNode.nodeName === 'A') {
			return;
		}

		let src = img.src;
		if (img.dataset.image !== undefined) {
			src = `${src}&original=1`;
		}

		img.outerHTML = `<a href="${src}" data-lightbox="article">${img.outerHTML}</a>`;
	});
}

import { createEmbed } from "@module/embed";

document.querySelectorAll('.embed-card').forEach(el => {
	createEmbed(el);
});

document.querySelectorAll('oembed[url]').forEach(el => {
	createEmbed(el, el.attributes.url.value);
});

document.querySelectorAll( 'div[data-oembed-url]' ).forEach( element => {
	// Discard the static media preview from the database (empty the <div data-oembed-url="...">).
	while ( element.firstChild ) {
		element.removeChild( element.firstChild );
	}

	createEmbed(element, element.dataset.oembedUrl);
} );

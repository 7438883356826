import elements from "../../@module/elements";

elements.delegateEvent('mousedown', '[data-confirm]', (el, event) => {
	event.preventDefault();

	if (!el.hasAttribute('href')) {
		return;
	}

	if (event.which === 1 || event.which === 2) {
		if (!confirm(el.dataset.confirm ? el.dataset.confirm : 'Opravdu pokračovat?')) {
			return;
		}

		if (event.which === 1) {
			window.location.href = el.getAttribute('href');
		} else {
			window.open(el.getAttribute('href'), '_blank');
		}
	}
}, null, true);

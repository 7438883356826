import elements from "@module/elements";
import pickout from './pickout';
import 'pickout/dist/pickout.min.css';
import apiRequest from "@module/api/request";

(() => {
	const selectBox = document.getElementById('bookmark-tags');
	if (!selectBox) {
		return;
	}

	const copiedOptions = selectBox.innerHTML;
	let active = null;

	pickout.to({
		el: '#bookmark-tags',
		search: true,
	});

	elements.delegateEvent('click', 'a.js-bookmark-add', (el, event) => {
		event.preventDefault();

		// add active tags to array
		const container = el.parentElement;
		const ids = [];
		container.querySelectorAll('[data-id]').forEach(el => {
			ids.push(el.dataset.id);
		});

		// refresh selectbox
		selectBox.innerHTML = copiedOptions;

		// remove active tags from select box
		selectBox.querySelectorAll('option').forEach(el => {
			const value = el.getAttribute('value');
			if (ids.includes(value)) {
				el.remove();
			}
		});

		active = el;
		selectBox.click();
	});

	selectBox.addEventListener('change', event => {
		const container = active.parentElement;
		const value = selectBox.value;
		const option = selectBox.options[selectBox.selectedIndex];
		const text = option.text;
		/** @var {string} */
		const href = active.dataset.href;
		/** @var {string} */
		const hrefDelete = active.dataset.hrefDelete;
		selectBox.value = '';

		apiRequest.processErrorsAndReturnRawData(
			apiRequest.getAbsolute(href.replace('PLACEHOLDER', value))
		).then(data => {
			if (data?.error !== false) {
				return;
			}

			const created = elements.createElementFromTemplate(
				`<span data-id="${value}" style="margin-right:3px;${option.dataset.style}" href="#">
					${text}
					<a href="${hrefDelete.replace('PLACEHOLDER', value)}" style="${option.dataset.color}" class="remove js-bookmark-delete">&#10005;</a>
				</span>`
			);
			container.insertBefore(created, active);
		});
	});

	elements.delegateEvent('click', 'a.js-bookmark-delete', (el, event) => {
		event.preventDefault();
		if (!confirm('Opravdu pokračovat?')) {
			return;
		}

		const href = el.getAttribute('href');

		apiRequest.processErrorsAndReturnRawData(
			apiRequest.getAbsolute(href)
		).then(data => {
			console.log(data);
			if (data?.error !== false) {
				return;
			}

			el.parentElement.remove();
		});
	});

})();

import injectLoadingEffect from './utils/loading';

export default function embedly(el, url) {
	if (url && el.tagName !== 'A') {
		const a = document.createElement('a');
		a.href = url;

		el.appendChild(a);
		el = a;
	}

	injectLoadingEffect(el);

	window.embedly('card', el, {
		key: 'df05e844764246e0aecaa8b924db6f16',
		controls: '0',
	});
}

import embedly from './providers/embedly';
import iframely from './providers/iframely';

export function findEmbedCardElements(el) {
	el.querySelectorAll('a.embed-card').forEach((el) => {
		createEmbed(el);
	});
}

export function createEmbed(el, url = null) {
	if (typeof window.embedly !== 'undefined') {
		embedly(el, url);
	} else if (typeof window.iframely !== 'undefined') {
		iframely(el, url);
	} else {
		console.error('Embed provider is undefined');
	}
}

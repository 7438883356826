import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import elements from '@module/elements';

elements.dynamicQuerySelectorAll('[data-step]', el => {

	const instance = tippy(el, {
		content(reference) {
			return reference.getAttribute('data-step');
		},
		showOnCreate: true,
		hideOnClick: false,
		trigger: 'manual',
		onMount(instance) {
			instance.popper.style.pointerEvents = 'auto';
		}
	});

	if (el.dataset.stepTarget !== undefined) {
		const target = el.dataset.stepTarget;

		el = document.querySelector(target) ?? throw new Error(`Target step element ${target} not exists`);
	}

	el.addEventListener('click', (event) => {
		instance.destroy();
	}, {
		once: true,
	});

});

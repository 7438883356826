import {delegate} from 'tippy.js';
import 'tippy.js/dist/tippy.css';

delegate(document.body, {
	target: '[data-tooltip]',
	content(reference) {
		const content = reference.getAttribute('title');
		if (content) {
			reference.removeAttribute('title');
			reference.setAttribute('data-tooltip', content);

			return content;
		}

		return reference.getAttribute('data-tooltip');
	}
});

import axios from "axios";
import createToast from "@module/components/toast";

function statusToError(code) {
	if (code === 500) {
		return {
			errors: [
				'Interní chyba serveru, zkuste to prosím později, pracujeme na nápravě',
			],
			reason: 'serverError',
		};
	}

	return {
		errors: [],
		reason: 'code',
	};
}

const apiRequest = {
	get(link, params = {}) {
		return this.getAbsolute(`/api/${link}`, params);
	},

	post(link, data = {}) {
		return this.postAbsolute(`/api/${link}`, data);
	},

	getAbsolute(link, params = {}) {
		return processRequest(
			axios.get(link, {
				params,
			})
		);
	},

	postAbsolute(link, data = {}) {
		return processRequest(
			axios.post(link, data)
		);
	},

	sendFormData(link, formData) {
		return processRequest(
			axios.post(`/api/${link}`, formData)
		);
	},

	processErrorsAndReturnResponse(promise, def = undefined) {
		return new Promise(resolve => {
			promise.then(response => {
				resolve(response);
			}).catch(createPromiseCatch(resolve, def));
		});
	},

	processErrorsAndReturnData(promise, def = undefined) {
		return new Promise(resolve => {
			promise.then(response => {
				resolve(response.data.data);
			}).catch(createPromiseCatch(resolve, def));
		});
	},

	processErrorsAndReturnRawData(promise, def = undefined) {
		return new Promise(resolve => {
			promise.then(response => {
				resolve(response.data);
			}).catch(createPromiseCatch(resolve, def));
		});
	},

	processErrorsSilentlyAndReturnData(promise, def = undefined) {
		return new Promise(resolve => {
			promise.then(response => {
				resolve(response.data.data);
			}).catch(createPromiseCatch(resolve, def, false));
		});
	}

};

function createPromiseCatch(resolve, def = undefined, toast = true) {
	return reason => {
		if (toast) {
			if (reason.reason === 'unknown') {
				createToast(null, 'Unexpected error, please try again later', 'error')
			} else if (reason.reason !== 'cancel') {
				reason.errors.forEach(error => {
					if (error.type === 'client') {
						createToast(null, error.message, 'error');
					}
				});
			}
		}

		resolve(def);
	};
}

function processRequest(promise) {
	return new Promise((resolve, reject) => {
		promise.then(value => {
			if (value.status >= 300 && values.status < 400) {
				return;
			}

			if (value.status >= 400) {
				reject(statusToError(value.status));

				return;
			}

			const data = value.data;

			if (data.success === false) {
				reject({
					errors: data.errors,
					reason: data.errors.length === 0 ? 'unknown' : 'error',
				});
			}

			resolve(value);
		}).catch(reason => {
			if (axios.isCancel(reason)) {
				reject({
					errors: [],
					reason: 'cancel',
				})
			} else {
				reject({
					errors: [],
					reason: 'unknown',
				});
			}
		});
	});
}

export default apiRequest;

import createToast from "@module/components/toast";

(() => {
	if (typeof window._stripeCheckout !== 'object') {
		return;
	}

	const options = window._stripeCheckout;
	if (typeof options.key !== 'string' || typeof options.sessionId !== 'string') {
		return;
	}

	const stripe = Stripe(options.key);

	stripe.redirectToCheckout({
		sessionId: options.sessionId,
	}).then(result => {
		if (result.error) {
			createToast(null, result.error.message, 'error');
		}
	})
})();


export default class InMemoryCache {

	#cache = {};

	fallback(key, callback) {
		if (!this.#cache.hasOwnProperty(key)) {
			const value = callback();
			if (value === undefined) {
				return undefined;
			}

			this.#cache[key] = value;
		}

		return this.#cache[key];
	}

}

import PromiseFileReader from './PromiseFileReader';

export default class PromiseFileReaders {

	#readers = [];

	constructor(files) {
		for (const file of files) {
			this.#readers.push(new PromiseFileReader(file));
		}
	}

	async #call(method, modifier = null) {
		const wait = [];
		this.#readers.forEach(reader => {
			wait.push(reader[method](modifier));
		});

		return await Promise.all(wait);
	}

	async readAsDataUrl(modifier = null) {
		return this.#call('readAsDataUrl', modifier);
	}

	async readAsBinaryString() {
		return this.#call('readAsBinaryString');
	}

	async readAsText() {
		return this.#call('readAsText');
	}

};

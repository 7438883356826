
export default class FileUploadReader {

	/** @type {FileList} */
	#fileList;

	/** @type {number} */
	#readType;

	/**
	 * @param {FileList} fileList
	 */
	constructor(fileList, type = FileUploadReader.DATA_URL) {
		this.#fileList = fileList;
		this.#readType = type;
	}

	static get DATA_URL() {
		return 1;
	}

	setReadType(type = FileUploadReader.DATA_URL) {
		this.#readType = type;
	}

	getSingleResult() {
		return new Promise((resolve, reject) => {
			if (this.#fileList.length === 0) {
				reject('FileList is empty');
				return;

			}

			const file = this.#fileList[0];
			const reader = new FileReader();

			reader.onload = (e) => {
				resolve(e.target.result);
			};

			reader.onerror = reject;

			switch (this.#readType) {
				case FileUploadReader.DATA_URL:
					reader.readAsDataURL(file);

					break;
				default:
					throw new Error(`Read type ${this.#readType} not exists`);
			}
		});
	}

}

/**
 * @param {FileList|File[]} fileList
 * @param {function(File):void} callback
 */
export function eachFile(fileList, callback) {
	for (let key = 0; key < fileList.length; key++) {
		const file = fileList[key];
		if (!file) {
			continue;
		}

		callback(file);
	}
}

/**
 * @param {FileList|File[]} fileList
 * @param {function(File)} callback
 * @returns {File[]}
 */
export function mapFile(fileList, callback) {
	const files = [];

	eachFile(fileList, file => {
		const ret = callback(file);
		if (ret !== null && typeof ret !== 'undefined') {
			files.push(ret);
		}
	});

	return files;
}

/**
 * @param {FileList} fileList
 * @param {null|string[]} mimeTypes
 * @param onError
 * @return {File[]}
 */
export function checkUploadFiles(fileList, mimeTypes = null, onError = null) {
	return mapFile(fileList, file => {
		if (mimeTypes === null || mimeTypes.includes(file.type)) {
			return file;
		} else {
			if (onError) {
				onError(file);
			}
		}
	});
}

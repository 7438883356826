import elements from "../../@module/elements";

function isNumeric(str) {
	return !isNaN(str) && !isNaN(parseInt(str));
}

elements.dynamicQuerySelectorAll('[data-truncate-height]', el => {
	if (!isNumeric(el.dataset.truncateHeight)) {
		console.error(`data-truncate-height must be numeric, ${el.dataset.truncateHeight} given`);

		return;
	}

	const force = el.dataset.truncateForce === '1';
	const height = parseInt(el.dataset.truncateHeight);

	if (force || height <= el.offsetHeight) {
		el.classList.add('truncated');
	} else {
		el.querySelector('.href-absolute')?.remove();
		el.classList.remove('truncated');
	}
});

elements.delegateEvent('click', '[data-truncate-show-more] .href-absolute', (el, event) => {
	event.preventDefault();

	const container = el.closest('[data-truncate-show-more]');

	container.classList.remove('truncated');
	el.remove();
});

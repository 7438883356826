import elements from "@module/elements";
import { createMentionOptions } from "@module/editor/mentions";

elements.dynamicQuerySelectorAll('.js-discussion .discussion-truncate', el => {
	if (el.offsetHeight < 100) {
		el.classList.remove('discussion-truncate');
	}
});

document.querySelectorAll('.js-discussion-comment-editor').forEach(el => {
	window.WarengoEditor.comment(el, {
		mention: createMentionOptions(window.Globals.links.mention)
	}).then(editor => {
		editor.ui.view.element.classList.add('ck-editor-one-line');
	});
});


export default class PromiseFileReader {

	#file;

	constructor(file) {
		this.#file = file;
	}

	async #read(method, modifier = null) {
		return new Promise(((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				let result = e.target.result;
				if (modifier) {
					result = modifier(result, this.#file, e);
				}

				resolve(result);
			};

			reader.onerror = () => {
				reject('File reader error.');
			};

			reader.onabort = () => {
				reject('File reader abort.');
			};

			reader[method](this.#file);
		}));
	}

	async readAsDataUrl(modifier = null) {
		return this.#read('readAsDataURL', modifier);
	}

	async readAsBinaryString() {
		return this.#read('readAsBinaryString');
	}

	async readAsText() {
		return this.#read('readAsText');
	}

}

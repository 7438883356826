import Filter from './lib';
import netteAjax from '@module/ajax/nette';

netteAjax.afterSnippetUpdate(el => {
	Filter.update(el);
});

Filter.initialize((link) => {
	netteAjax.makeRequest('GET', link, null, {
		history: false,
	});
}, {
	rootMargin: '300px'
});


class Observer {

	constructor(rootMargin, callback) {
		this.observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					callback(entry.target);
					observer.unobserve(entry.target);
					this.map.delete(entry.target);
				}
			});
		}, {
			rootMargin,
		});

		this.map = new WeakMap();
	}

	/**
	 * @param {Element|NodeList} target
	 */
	observe(target) {
		if (target instanceof NodeList) {
			target.forEach(element => {
				if (this.map.has(element)) {
					return;
				}
				this.observer.observe(element);
				this.map.set(element, true);
			});

		} else if (!this.map.has(target)) {
			this.observer.observe(target);
			this.map.set(target, true)

		}
	}

}

class FilterClass {

	/** @type {Observer} */
	observer;

	constructor(ajax, {
		rootMargin = '0px'
	} = {}) {
		this.ajax = ajax;
		this.observer = new Observer(rootMargin, (element) => {
			this.intersection(element);
		});
	}

	/**
	 * @param {HTMLElement|Document} context
	 */
	searchTargets(context = null) {
		if (!context) {
			context = document;
		}


		this.observer.observe(context.querySelectorAll('[data-infinite-scroll]'));
	}

	/**
	 * @param {HTMLElement} element
	 */
	intersection(element) {
		this.ajax(element.getAttribute('href'));
	}

}

const Filter = {

	observer: null,

	object: null,

	initialize(ajax, options = {}) {
		this.object = new FilterClass(ajax, options);
		this.object.searchTargets();
	},

	update(context = null) {
		this.object.searchTargets(context);
	}

};

export default Filter;

import axios from "axios";

function mentionFetchUsers(link) {
	return (text) => {
		const requestLink = link.replace('%24placeholder%24', encodeURI(text));


		return new Promise((resolve, reject) => {
			axios.get(requestLink)
				.then(response => {
					resolve(response.data.map(item => {
						item.id = `@${item.nick}`;

						return item;
					}));
				})
				.catch(error => {
					reject(error);
				})
		});
	};
}

function mentionCustomItemRenderer(item) {
	const itemElement = document.createElement('span');
	const avatar = document.createElement('img');
	const userNameElement = document.createElement('span');
	const fullNameElement = document.createElement('span');

	itemElement.classList.add('mention__item');

	avatar.src = item.avatar;

	userNameElement.classList.add('mention__item__user-name');
	userNameElement.textContent = item.id;

	fullNameElement.classList.add('mention__item__full-name');
	fullNameElement.textContent = item.name;

	itemElement.appendChild(avatar);
	itemElement.appendChild(userNameElement);
	itemElement.appendChild(fullNameElement);

	return itemElement;
}

export function createMentionOptions(mentionUrl) {
	return {
		feeds: [createMentionFeed(mentionUrl)],
	};
}

export function createMentionFeed(mentionUrl) {
	return {
		marker: '@',
		feed: mentionFetchUsers(mentionUrl),
		itemRenderer: mentionCustomItemRenderer,
	};
}

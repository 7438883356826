import {createPopper} from "@popperjs/core";
import elements from "@module/elements";

/**
 * @param {Element} target
 * @param {Element} dropdown
 * @returns {object}
 */
export default function dropdownFactory(target, dropdown) {
	const dropdownMenu = dropdown.querySelector('.dropdown-menu');

	if (!dropdownMenu) {
		throw new Error('.dropdown-menu not found');
	}

	const object = {
		element: dropdownMenu,
		isVisible() {
			return dropdownMenu.style.display === 'block';
		},
		hide() {
			dropdownMenu.style.display = 'none';
		},
		toggle() {
			dropdownMenu.style.display = dropdownMenu.style.display === 'block' ? 'none' : 'block';
		},
		show() {
			dropdownMenu.style.display = 'block';
		},
	};

	elements.onClickOutside(dropdown, (event) => {
		if (!object.isVisible()) {
			return;
		}

		object.hide();
	});

	// visibility
	target.addEventListener('click', () => {
		object.toggle();
	});

	// create popper
	target.addEventListener('click', () => {
		const modifiers = [
			{
				name: 'offset',
				options: {
					offset: [0, 5]
				}
			}
		];

		if (target.dataset.preventOverflow !== undefined) {
			const boundary = target.dataset.boundary ? target.closest(target.dataset.boundary) : 'clippingParents';
			if (!boundary) {
				throw new Error(`Boundary ${boundary} not found`);
			}

			modifiers.push({
				name: 'preventOverflow',
				options: {
					boundary: boundary,
					padding: {
						right: 5,
					}
				}
			});
		}

		createPopper(target, dropdownMenu, {
			placement: 'bottom',
			modifiers,
		});
	}, {
		once: true,
	});

	target.style.userSelect = 'none';

	return object;
}

import elements from "@module/elements";

elements.dynamicQuerySelectorAll('a.mention[data-user]', el => {
	const id = el.dataset.user;

	el.setAttribute('target', '__blank');
	el.setAttribute('href', `/profile/perma/${id}`);

	el.setAttribute('data-controller', 'popover');
	el.setAttribute('data-popover-link-value', `/api/accounts/popover/${id}`);
});

import isFormSubmit from './lib/isForm';

export default class CleanFormExtension {

	constructor(naja) {
		naja.addEventListener('interaction', this.interaction.bind(this));

		naja.addEventListener('success', (args) => {
			if (!args.options._cleanForm) {
				return;
			}

			args.options._cleanForm.reset();
		});
	}

	interaction({element, options}) {
		let form = null;
		if (element.tagName === 'FORM') {
			form = element;
		} else if (element.getAttribute('type') === 'submit') {
			form = element.closest('form');
		} else {
			return;
		}

		if (form.classList.contains('js-form-clean')) {
			options._cleanForm = form;
		}
	}

}

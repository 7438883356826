
document.querySelectorAll('[data-file-upload]').forEach(el => {
	const inputName = el.dataset.fileUpload;
	const form = el.closest('form');
	if (!form) {
		throw new Error(`Form not found for [data-file-upload]`);
	}
	const input = form[inputName];

	if (input === undefined) {
		throw new Error(`Input ${inputName} not found for [data-file-upload]`);
	}

	el.addEventListener('click', () => {
		input.click();
	});

	if (el.dataset.fileUploadChangeSubmit !== undefined) {
		el.addEventListener('change', () => {
			form.submit();
		}, {
			once: true
		});
	}

});

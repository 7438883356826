import elements from '@module/elements';

elements.dynamicQuerySelectorAll('.js-ui-block-hideable', el => {
	if (!el.classList.contains('ui-block')) {
		throw new Error('must have class .ui-block');
	}

	const contentEl = el.querySelector('.ui-block-content');
	const titleEl = el.querySelector('.ui-block-title');

	if (!contentEl) {
		throw new Error('.ui-block-content not found');
	}

	if (!titleEl) {
		throw new Error('.ui-block-title not found');
	}

	if (!el.classList.contains('inactive')) {
		elements.toggleClass(contentEl, 'hide-js');
		elements.toggleClass(el, 'inactive');
	}

	titleEl.addEventListener('click', () => {
		elements.toggleClass(contentEl, 'hide-js');
		elements.toggleClass(el, 'inactive');
	});
});

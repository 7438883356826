import Vue from 'vue';
import VueResource from 'vue-resource';

import each from 'foreach';

import StatusForm from './Status/StatusForm';

import * as VueTruncate from 'vue-truncate-filter';
import elements from '@module/elements';
import Score from "./Score/Score";

const mapping = {
	'.vue-status-form': StatusForm,
	'.vue-score': Score
};

Vue.use(VueResource);
Vue.use(VueTruncate);

// logic

/**
 * @param {NodeListOf<HTMLElement>} elements
 * @param {object} object
 */
function factory(elements, object, ...startupArguments) {
	if (!elements.length) {
		return;
	}

	const constructor = Vue.extend(object);

	elements.forEach(el => {
		const object = new constructor();
		let target = el;

		// call startup
		if (typeof object.$options.startup === 'function') {
			object.$options.startup.call(object, el, ...startupArguments);
		}

		if (el.dataset.vueAppend !== undefined) {
			target = document.createElement('div');
			el.appendChild(target);
		}

		if (el.dataset.vueBefore !== undefined) {
			target = document.createElement('div');
			el.parentNode.insertBefore(target, el);
		}

		object.$mount(target);
	});
}

elements.dynamicQuerySelectorAllCallback(root => {
	each(mapping, (object, selector) => {
		factory(root.querySelectorAll(selector), object);
	});
});

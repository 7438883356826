
function slideByKey(slides, active) {
	slides.forEach((el, key) => {
		el.style.display = key === active ? 'block' : 'none';
	});
}

function initSlideshow() {
	document.querySelectorAll('.__slideshow').forEach(slideshow => {
		const slides = slideshow.querySelectorAll('.slide');
		const count = slides.length;
		let active = 1;

		if (count <= 1) {
			return;
		}

		setInterval(() => {
			slideByKey(slides, active++);

			if (active >= count) {
				active = 0;
			}
		}, 7e3);
	});
}

if (window.matchMedia('(max-width: 799px)').matches) {
	initSlideshow();
}

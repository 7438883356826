
function calculate() {

	const root = document.getElementById('messenger');

	if (!root) {
		return;
	}

	if (window.innerWidth < 1200) {
		return;
	}

	const form = root.querySelector('form.form--messenger');
	if (!form) {
		return;
	}

	const windowHeight = window.innerHeight;
	const menuHeight = document.querySelector('.__menu').offsetHeight + 15 + 15;
	const messengerHeaderHeight = root.querySelector('.messenger__heading').offsetHeight;
	const formHeight = root.querySelector('form.form--messenger').offsetHeight;
	const headerHeight = root.querySelector('.messenger-conversation-top').offsetHeight;

	// conversations
	const leftHeight = windowHeight - menuHeight - messengerHeaderHeight;
	const conversationsElement = root.querySelector('.messenger__conversations__scroller');
	conversationsElement.style.height = leftHeight.toString() + 'px';

	// conversation
	const rightHeight = leftHeight - headerHeight - formHeight;
	const conversationElement = root.querySelector('.messenger-conversation');
	conversationElement.style.height = rightHeight.toString() + 'px';

	conversationElement.scrollTop = conversationElement.scrollHeight;


}

calculate();

window.addEventListener('resize', calculate);


import * as uploadUtils from 'modules/forms/upload/utils';
import createToast from "@module/components/toast";

/**
 * @param {FileList} fileList
 * @return {File[]}
 */
export function checkUploadFiles(fileList) {
	let files = uploadUtils.mapFile(fileList, file => {
		if (file.size > window.App.maxImageSize.bytes) {
			// @translate
			createToast(null, `Obrázek <strong>${file.name}</strong> je příliš velký. Maximální velikost obrázku je ${window.App.maxImageSize.text}`, 'error');
		} else {
			return file;
		}
	});

	return uploadUtils.checkUploadFiles(files, ['image/jpeg', 'image/png', 'image/gif'], (file) => {
		// @translate
		createToast(null, `Soubor <strong>${file.name}</strong> není v podporovaném formátu. Povoleny jsou jenom png, jpg, gif.`, 'error');
	});
}

import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['counter'];
	static values = {
		counter: Number,
		link: String,
	};

	click() {
		if (this.counterValue !== 0) {
			this.counterValue = 0;

			this.counterTarget.classList.add('hidden');

			fetch(this.linkValue);
		}
	}

}

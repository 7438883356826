import { createMentionFeed } from "@module/editor/mentions";

function storyForm() {
	const editor = document.querySelector('.js-story-editor');
	const elementWordCount = document.getElementById('editor-wordCount');
	const elementCharCount = document.getElementById('editor-charCount');

	if (!editor) {
		return;
	}

	if (typeof WarengoEditor === 'undefined') {
		throw new Error('Warengo editor is not defined.');
	}

	const feeds = [];

	feeds.push(createMentionFeed(window.Globals.links.mention));

	WarengoEditor.story(editor, {
		placeholder: editor.getAttribute('placeholder'),
		mention: {
			feeds,
		},
		mediaEmbed: {
			extraProviders: [
				{
					name: 'Google forms',
					url: /^docs.google.com\/forms\//
				}
			]
		},
		image: {
			toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],
			styles: [ 'full', 'alignLeft', 'alignRight' ],
		},
		indentBlock: {
			classes: [
				'indent-1',
				'indent-2',
				'indent-3',
			]
		},
		wordCount: {
			onUpdate: stats => {
				if (elementCharCount) {
					elementCharCount.innerText = stats.characters;
				}
				if (elementWordCount) {
					elementWordCount.innerText = stats.words;
				}
			}
		}
	});
}

storyForm();

import elements from '@module/elements';
import tippy from 'tippy.js';

const CHARS_LEFT_MESSAGE = 'Zbývá %value% znaků';

function tooltip(el, instance, length, message) {
	const valueLen = el.value.length;
	const left = length - valueLen;

	instance.setContent(message.replace('%value%', left.toString()));
}

elements.dynamicQuerySelectorAll('[data-chars-left]', el => {
	if (!el.hasAttribute('maxlength')) {
		return;
	}

	const length = parseInt(el.getAttribute('maxlength'));
	const message = el.dataset.charsLeft || CHARS_LEFT_MESSAGE;

	const instance = tippy(el, {
		content: '',
		trigger: 'manual',
	});

	el.addEventListener('focus', () => {
		tooltip(el, instance, length, message);
		instance.show();
	});

	el.addEventListener('keyup', () => {
		tooltip(el, instance, length, message);
	});

	el.addEventListener('blur', () => {
		instance.hide();
	});

});

import elements from '@module/elements';

function uploadPreview() {
	const el = document.querySelector('.js-story-upload-preview');

	if (!el) {
		return;
	}

	const input = el.querySelector('input[type="file"]');
	const checkbox = el.querySelector('input[type="checkbox"]');
	const imageWrapper = el.querySelector('.js-story-upload-preview--img-wrapper');
	const del = el.querySelector('.js-story-upload-preview--delete');
	const placeholder = el.querySelector('img').dataset.placeholder;

	if (!input) {
		throw new Error('input type file not found');
	}

	if (!imageWrapper) {
		throw new Error('img-wrapper not found');
	}

	if (!del) {
		throw new Error('img delete not found');
	}

	if (checkbox) {
		del.classList.remove('hidden');
	}

	del.addEventListener('click', (e) => {
		e.preventDefault();

		if (checkbox) {
			checkbox.checked = true;
		}

		del.classList.add('hidden');

		const img = imageWrapper.querySelector('img');
		if (!img) {
			return;
		}

		if (!placeholder) {
			img.remove();
		} else {
			img.src = placeholder;
		}

		input.value = '';
	});

	input.addEventListener('change', e => {
		if (!input.files.length || !input.files[0]) {
			return;
		}

		const reader = new FileReader();

		reader.onload = (e) => {
			const result = e.target.result;
			let img = imageWrapper.querySelector('img');
			if (!img) {
				img = document.createElement('img');
				img.classList.add('wch-upload-preview');
			}

			img.src = result;

			del.classList.remove('hidden');
		};

		reader.readAsDataURL(input.files[0]);
	});

}

uploadPreview();

import elements from '@module/elements';
import flatpickr from 'flatpickr';
import { Czech } from 'flatpickr/dist/l10n/cs';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/flatpickr.css';
import './fix.css';

function typeToDateFormat(type) {
	switch (type) {
		case 'date':
			return 'Y-m-d';
		case 'datetime-local':
			return 'Y-m-d H:i';
		case 'month':
			return 'Y-m';
		case 'week':
			return 'Y-\WW';
		default:
			return 'Y-m-d';
	}
}

function onMonthOrYearChange(dObj, dStr, fp) {
	fp.setDate(new Date(fp.currentYear, fp.currentMonth));
}

elements.dynamicQuerySelectorAll('.js-date-control', (el) => {
	// mobile, tablet
	if (window.innerWidth < 960) {
		return;
	}

	const format = el.getAttribute('data-format');
	const type = el.getAttribute('type');
	const options = {
		allowInput: true,
		altInput: true,
		enableTime: type === 'datetime-local',
		altFormat: typeToDateFormat(type),
		dateFormat: format,
		locale: Czech,
		onYearChange: onMonthOrYearChange,
		onMonthChange: onMonthOrYearChange,
	};

	if (type === 'month') {
		options.plugins = [
			new monthSelectPlugin({
				dateFormat: typeToDateFormat(type),
				altFormat: format,
			})
		];
	}

	flatpickr(el, options);
});

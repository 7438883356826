import elements from '@module/elements';

elements.delegateEvent('click', '.js-tabs [data-tab]', (el, event) => {
	const targetSelector = el.getAttribute('data-tab');

	if (targetSelector === null) {
		throw new Error('Unexpected error');
	}

	const container = el.closest('.js-tabs');

	if (container === null) {
		throw new Error('Unexpected error');
	}

	const tabTargetSelector = `[data-tab-target="${targetSelector}"]`;
	const tabs = container.querySelectorAll(tabTargetSelector);

	// remove .active
	container.querySelectorAll('[data-tab].active').forEach(el => {
		el.classList.remove('active');
	});
	container.querySelectorAll('[data-tab-target].active').forEach(el => {
		el.classList.remove('active');
	});

	// add .active
	el.classList.add('active');
	tabs.forEach(el => {
		el.classList.add('active');
	});
});

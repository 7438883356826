import netteAjax from '@module/ajax/nette';
import netteForms from 'nette-forms';
import FormStateButtonExtension from './extensions/FormStateButtonExtension';
import {IS_DEBUG} from '@module/environment';
import DebugExtension from './extensions/DebugExtension';
import CleanFormExtension from './extensions/CleanFormExtension';
import EmbedlyAjaxExtension from "./extensions/EmbedlyAjaxExtension";

netteAjax.registerExtension(FormStateButtonExtension, 'Odesílání...');
netteAjax.registerExtension(CleanFormExtension);
netteAjax.registerExtension(EmbedlyAjaxExtension);
netteAjax.registerNetteForms(netteForms);

if (IS_DEBUG) {
	netteAjax.registerExtension(DebugExtension);
}

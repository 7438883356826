import naja from 'naja/dist/Naja';

const afterSnippetUpdateCallbacks = [];
const beforeSnippetUpdateCallbacks = [];

const EVENTS = ['init', 'success', 'abort'];

const netteAjax = {

	context: null,

	makeRequest(method, url, data = null, options = {}) {
		return naja.makeRequest(method, url, data, options);
	},

	submitForm(form) {
		naja.uiHandler.submitForm(form);
	},

	bindUi(element) {
		naja.uiHandler.bindUI(element);
	},

	/**
	 * @param {function(el: HTMLElement, event: Event): void} callable
	 */
	afterSnippetUpdate(callable) {
		if (typeof callable !== 'function') {
			throw new Error('Is not callable');
		}

		afterSnippetUpdateCallbacks.push(callable);
	},

	/**
	 * @param {function(el: HTMLElement, event: Event): void} callable
	 */
	beforeSnippetUpdate(callable) {
		if (typeof callable !== 'function') {
			throw new Error('Is not callable');
		}

		beforeSnippetUpdateCallbacks.push(callable);
	},

	extension(object) {
		if (typeof object.afterSnippet === 'function') {
			naja.snippetHandler.addEventListener('afterUpdate', (event) => {
				object.afterSnippet(event.snippet, event);
			});
		}

		EVENTS.forEach(name => {
			if (typeof object[name] === 'function') {
				naja.addEventListener(name, object[name].bind(object));
			}
		});
	},

	registerExtension(extension, ...options) {
		naja.registerExtension(extension, ...options);
	},

	registerNetteForms(netteForms) {
		naja.formsHandler.netteForms = netteForms;
	},

	initialize() {
		document.addEventListener('DOMContentLoaded', () => {
			naja.initialize({
				history: false,
			});
		});
	},

};

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	afterSnippetUpdateCallbacks.forEach(callback => {
		callback(event.snippet, event);
	});
});

naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
	beforeSnippetUpdateCallbacks.forEach(callback => {
		callback(event.snippet, event);
	});
});

netteAjax.context = naja;

export default netteAjax;

import elements from "../../@module/elements";

elements.delegateEvent('click', '[data-toggle]', el => {
	const target = document.querySelector(el.dataset.toggle);
	if (!target) {
		console.error(`Element with ${el.dataset.toggle} not found`);

		return;
	}

	const toggler = new Toggler(target);

	toggler.toggle();

	if (el.dataset.activeClass) {
		if (toggler.isVisible()) {
			el.classList.add(el.dataset.activeClass);
		} else {
			el.classList.remove(el.dataset.activeClass);
		}
	}
});

class Toggler {

	#el;

	constructor(el) {
		this.#el = el;
	}

	isVisible() {
		return this.#el.style.display === 'block';
	}

	hide() {
		this.#el.style.display = 'none';
	}

	toggle() {
		this.#el.style.display = this.#el.style.display === 'block' ? 'none' : 'block';
	}

	show() {
		this.#el.style.display = 'block';
	}

}

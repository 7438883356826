document.querySelectorAll('.js-upload-preview').forEach(container => {
	const previewWrapper = container.querySelector('.image-upload-preview-wrapper');
	const placeholder = container.querySelector('.image-upload-preview-placeholder');
	const input = container.querySelector('[type="file"]');

	if (!previewWrapper) {
		throw new Error('Preview wrapper not found');
	}
	if (!input) {
		throw new Error('Preview input not found');
	}

	input.addEventListener('change', el => {
		if (!input.files || !input.files[0]) {
			return;
		}

		const reader = new FileReader();

		reader.onload = e => {
			let img = previewWrapper.querySelector('img');
			if (!img) {
				img = document.createElement('img');
				previewWrapper.appendChild(img);
			}

			previewWrapper.classList.remove('empty');

			if (placeholder) {
				placeholder.remove();
			}

			img.setAttribute('src', e.target.result);
		};

		reader.readAsDataURL(input.files[0]);
	});
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-block __status-form--outer" }, [
    _c("div", { staticClass: "ui-block-content" }, [
      _c("div", { staticClass: "__components-tab-list" }, [
        _c("div", { staticClass: "active item" }, [
          _c("span", { staticClass: "fa fa-comment" }),
          _vm._v(" "),
          _c("span", { staticClass: "hidden md:inline-block" }, [
            _vm._v(_vm._s(_vm.template.statusCaption[0]))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "capitalize md:lowercase" }, [
            _vm._v(_vm._s(_vm.template.statusCaption[1]))
          ])
        ]),
        _vm._v(" "),
        _vm.options.storyTab && _vm.template.showStoryTab
          ? _c("div", { staticClass: "item" }, [
              _c("a", { attrs: { href: _vm.options.storyUrl } }, [
                _c("span", { staticClass: "fa fa-edit" }),
                _vm._v(" "),
                _c("span", { staticClass: "hidden md:inline-block" }, [
                  _vm._v("Napsat")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "capitalize md:lowercase" }, [
                  _vm._v("článek")
                ])
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.hide,
            expression: "hide"
          }
        ],
        staticClass: "__status-form"
      },
      [
        _c("div", { staticClass: "hidden" }, [
          _c("input", {
            ref: "upload",
            attrs: { type: "file", multiple: "", name: "files" },
            on: { change: _vm.onUpload }
          })
        ]),
        _vm._v(" "),
        _vm.template.subject
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visible,
                    expression: "visible"
                  }
                ]
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subject,
                      expression: "subject"
                    }
                  ],
                  ref: "subject",
                  staticClass: "text-inline-subject",
                  attrs: { type: "text", placeholder: "Nadpis" },
                  domProps: { value: _vm.subject },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.subject = $event.target.value
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "textarea",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value"
              }
            ],
            ref: "content",
            staticClass: "status-editor-vue-textarea",
            class: ["status-content", { focus: _vm.visible }],
            attrs: { placeholder: _vm.template.placeholder },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.value = $event.target.value
              }
            }
          },
          [_vm._v(_vm._s(_vm.options.values.content))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.embedLink,
                expression: "embedLink"
              }
            ],
            staticStyle: { padding: "0 25px" }
          },
          [
            _c("div", { staticClass: "relative __c-close" }, [
              _c("div", {
                staticClass: "close-circle",
                on: {
                  click: function($event) {
                    _vm.embedLink = null
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { ref: "embedLinkContainer" })
            ])
          ]
        ),
        _vm._v(" "),
        _vm.images
          ? _c(
              "div",
              { staticClass: "__gallery mt-20 px-20" },
              _vm._l(_vm.images, function(image, key) {
                return _c("div", { staticClass: "__c-close" }, [
                  _c("div", {
                    staticClass: "close-circle",
                    on: {
                      click: function($event) {
                        return _vm.removeImage(key)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("img", { attrs: { src: image.content, alt: "photo" } })
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "flex justify-between content-center p-10",
              { hidden: !_vm.visible }
            ],
            staticStyle: {
              "border-top": "1px solid #d7dbe3",
              "margin-top": "20px"
            }
          },
          [
            _c("div", { staticClass: "icons" }, [
              _c(
                "div",
                {
                  staticClass: "icon-wrapper",
                  attrs: { "data-tooltip": "", title: "Přidat fotky" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "icon-list-wrapper pointer js-status-image-upload-target",
                      on: {
                        click: function($event) {
                          return _vm.$refs.upload.click()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass:
                          "far fa-image fa-lg text-light hover:text-normal"
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "icon-wrapper",
                  attrs: {
                    "data-tooltip": "",
                    title: "Přidat odkaz nebo video"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "pointer icon-list-wrapper",
                      on: {
                        click: function($event) {
                          return _vm.insertUrl("Zadejte odkaz nebo video")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass:
                          "fas fa-globe fa-lg text-light hover:text-normal"
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "button button-color_primary",
                  on: { click: _vm.onSubmit }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.template.statusCaption.join(" ")))
                  ])
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import elements from "@module/elements";
import debounce from "lodash/debounce";
import apiRequest from "@module/api/request";

elements.dynamicQuerySelectorAll('[data-autosave]', el => {
	const link = el.dataset.autosave;
	let target = el.dataset.autosaveTarget;
	let timeout = null;
	if (target) {
		target = document.querySelector(target);
	}

	const save = debounce((note) => {
		const data = new FormData();
		data.set('note', note);

		apiRequest.processErrorsAndReturnData(
			apiRequest.postAbsolute(link, data)
		).then(() => {
			if (target) {
				if (timeout) {
					clearTimeout(timeout);
					timeout = null;
				}
				target.classList.remove('hidden')
				timeout = setTimeout(() => {
					target.classList.add('hidden');
					timeout = null;
				}, 1500);
			}
		});
	}, 1e3);

	el.addEventListener('input', ev => {
		save(el.value);
	});
});

import { findEmbedCardElements } from "@module/embed";

export default class EmbedlyAjaxExtension {

	constructor(naja) {
		naja.snippetHandler.addEventListener('afterUpdate', (event) => {
			findEmbedCardElements(event.snippet);
		});
	}

}

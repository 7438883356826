document.querySelectorAll('[data-maxlen-countdown]').forEach(el => {
	const target = el.querySelector(el.dataset.maxlenCountdown);
	if (!el.hasAttribute('maxLength')) {
		el = el.querySelector('input,textarea');
	}

	const maxLen = el.getAttribute('maxLength');
	const calculate = () => {
		target.innerText = maxLen - el.value.length;
	};

	calculate();
	el.addEventListener('keydown', calculate);
});

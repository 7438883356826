
// odesilaci tlacitko komentar
import { createMentionOptions } from "@module/editor/mentions";
import elements from '@module/elements';
import netteAjax from "@module/ajax/nette";

if (window.location.hash.match(/#com-[0-9]+/)) {
	const el = document.querySelector(window.location.hash);
	if (el !== null) {
		el.style.border = '1px solid #000';
		setTimeout(() => el.scrollIntoView({
			behavior: 'smooth',
		}), 1);
	}
}

elements.delegateEvent('focusin', '.__comments textarea.comment-textarea', el => {
	const {emoji, form} = findTextareaElements(el);

	createEditor(el, form, emoji, editor => {
		editor.editing.view.focus();
	});
});

elements.delegateEvent('click', '.comment-reply-a', (el) => {
	const parent = el.closest('.comment-item-parent');
	const editor = parent.querySelector('.comment-textarea-wrapper .ck-editor__editable');
	if (editor) {
		editor.focus();

		return;
	}

	const {commentItem, child, commentReply, textarea} = findCommentElements(parent);

	commentItem.classList.add('has-children');
	child.classList.remove('hidden');
	commentReply.classList.remove('hidden');

	const {emoji, form} = findTextareaElements(textarea);

	if (el.dataset.mention) {
		textarea.value = `<a class="mention mention-mention" data-mention="${el.dataset.mention}">${el.dataset.mention}</a>&nbsp;`;
	}

	createEditor(textarea, form, emoji, editor => {
		editor.editing.view.focus();

		editor.model.change( writer => {

			writer.setSelection( writer.createPositionAt( editor.model.document.getRoot(), 'end' ) );
		} );
	});
});

function createFocusEvent(form) {
	return () => {
		form.querySelectorAll('.hide-js').forEach(el => {
			el.classList.add('__hide_js');
			el.classList.remove('hide-js');
		});
	};
}

function createBlurEvent(form) {
	return (editor) => {
		if (editor.getData()) {
			return;
		}

		form.querySelectorAll('.__hide_js').forEach(el => {
			el.classList.remove('__hide_js');
			el.classList.add('hide-js');
		});
	};
}

function createEditor(textarea, form, emoji, successCallback = null) {
	const focusEvent = createFocusEvent(form);
	const blurEvent = createBlurEvent(form);

	window.WarengoEditor.comment(textarea, {
		mention: createMentionOptions(window.Globals.links.mention)
	}).then((editor) => {
		editor.ui.focusTracker.on('change:isFocused', (evt, name, isFocused) => {
			if (isFocused) {
				focusEvent();
			} else {
				blurEvent(editor);
			}
		});

		form.elements.send.addEventListener('click', (event) => {
			event.preventDefault();

			textarea.value = editor.getData();

			netteAjax.submitForm(form);

			editor.setData('');
		});

		if (successCallback !== null) {
			successCallback(editor);
		}

		if (!emoji) {
			return;
		}

		emoji.addEventListener('click', () => {
			editor.plugins.get('Emoji').showPanel();
		});
	});
}

function findTextareaElements(textarea) {
	return {
		emoji: textarea.closest('.comment-textarea-wrapper')?.querySelector('.emoji-bar'),
		form: textarea.form
	};
}

function findCommentElements(commentItem) {
	const elements = {
		commentItem,
		child: commentItem.querySelector('.children'),
	};

	if (!elements.child) {
		throw new Error('.children not found');
	}

	elements.commentReply = elements.child.querySelector('.comment-reply');
	if (!elements.commentReply) {
		throw new Error('.comment-reply not found');
	}

	elements.textarea = elements.child.querySelector('textarea');
	if (!elements.textarea) {
		throw new Error('textarea not found');
	}

	return elements;
}

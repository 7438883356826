import { createPopper } from "@popperjs/core";
import { useHover } from "stimulus-use";

export default class Popover {

	/** @var {Element} */
	element;

	/** @var {Popper} */
	popper;

	/** @var {Number|null} */
	active = null;

	/** @var {Boolean} */
	#timer = false;

	/** @var {Number|null} */
	#previousActive = null;

	#factory;

	constructor(identifierClasses, options = {}) {
		this.element = Popover.#createElement(identifierClasses);
		this.popper = createPopper(document.body, this.element, options);
		this.#factory = (reference) => createPopper(reference, this.element, options);

		this.element.addEventListener('mouseenter', () => this.mouseEnter());
		this.element.addEventListener('mouseleave', () => this.mouseLeave());
	}

	static #createElement(identifierClasses) {
		const element = document.createElement('div');
		element.classList.add(...identifierClasses, 'hidden');

		document.body.appendChild(element);

		return element;
	}

	mouseEnter() {
		this.active = this.#previousActive;
	}

	mouseLeave() {
		this.deactivate(this.#previousActive);
	}

	activate(reference, html, id) {
		if (this.active !== null) {
			return;
		}

		this.active = id;

		this.element.innerHTML = html;
		this.element.classList.remove('hidden');

		this.popper = (this.#factory)(reference);
		setTimeout(() => {
			this.popper.update();
		}, 1);
	}

	deactivate(id) {
		if (this.active !== id) {
			return;
		}

		this.#previousActive = this.active;
		this.active = null;

		this.#hide();
	}

	#hide() {
		if (this.#timer === true) {
			return;
		}

		this.#timer = true;
		setTimeout(() => {
			this.#timer = false;
			if (this.active !== null) {
				return;
			}

			this.element.classList.add('hidden');
			this.popper.destroy();
		}, 250);
	}
};

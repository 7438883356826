import elements from "@module/elements";
import createToast from "@module/components/toast";


const imageControls = [
	'WebChemistry\\Images\\Controls\\UploadControl::validateMaxSize',
	'WebChemistry\\Images\\Controls\\AdvancedUploadControl::validateMaxSize'
];

elements.dynamicQuerySelectorAll('[data-nette-rules]', el => {
	// image max size
	const json = JSON.parse(el.dataset.netteRules);
	json.forEach(obj => {
		if (imageControls.includes(obj.op) && obj.arg > 0) {
			el.addEventListener('change', (event) => {
				/** @type FileList */
				const files = event.target.files;
				if (!event.target.files) {
					return;
				}

				for (const file of files) {
					if (file.size > obj.arg) {
						event.preventDefault();
						event.stopImmediatePropagation();
						el.value = '';

						createToast(null, obj.msg, 'error');

						return;
					}
				}
			});
		}
	});
});

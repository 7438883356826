<template>
	<div class="ui-block __status-form--outer">
		<div class="ui-block-content">
			<div class="__components-tab-list">
				<div class="active item">
					<span class="fa fa-comment"></span> <span class="hidden md:inline-block">{{template.statusCaption[0]}}</span> <span class="capitalize md:lowercase">{{template.statusCaption[1]}}</span>
				</div>
				<div v-if="options.storyTab && template.showStoryTab" class="item">
					<a v-bind:href="options.storyUrl">
						<span class="fa fa-edit"></span> <span class="hidden md:inline-block">Napsat</span> <span class="capitalize md:lowercase">článek</span>
					</a>
				</div>
			</div>
		</div>

		<div class="__status-form" v-click-outside="hide">

			<!-- Hidden inputs -->
			<div class="hidden">
				<input ref="upload" type="file" @change="onUpload" multiple name="files">
			</div>

			<!-- Author thumb -->
			<!--
			<div v-bind:class="['author-thumb', { hidden: visible }]">
				<img v-bind:src="User.avatar.thumb" v-bind:alt="User.name">
			</div>
			-->

			<!-- Content -->
<!--			<div v-bind:class="['placeholder', { hidden: visible }, 'fs_light']">-->
<!--				Sdílejte své myšlenky...-->
<!--			</div>-->
			<div v-if="template.subject" v-show="visible">
				<input class="text-inline-subject" ref="subject" v-model="subject" type="text" placeholder="Nadpis">
			</div>
			<textarea class="status-editor-vue-textarea"
					  :placeholder="template.placeholder"
					  ref="content" v-model="value"
					  v-bind:class="['status-content', { focus: visible }]">{{options.values.content}}</textarea>

			<!-- Embedly -->
			<div v-show="embedLink" style="padding: 0 25px">
				<div class="relative __c-close">
					<div class="close-circle" @click="embedLink = null"></div>

					<div ref="embedLinkContainer"></div>
				</div>
			</div>

			<!-- Gallery -->
			<div v-if="images" class="__gallery mt-20 px-20">
				<div v-for="(image, key) in images" class="__c-close">
					<div class="close-circle" @click="removeImage(key)"></div>
					<img v-bind:src="image.content" alt="photo">
				</div>
			</div>

			<!-- Bottom nav -->
			<div v-bind:class="['flex justify-between content-center p-10', { hidden: !visible }]"
				 style="border-top: 1px solid #d7dbe3;margin-top: 20px">

				<!-- Action icons -->
				<div class="icons">
					<div class="icon-wrapper" data-tooltip="" title="Přidat fotky">
						<a @click="$refs.upload.click()"
						   class="icon-list-wrapper pointer js-status-image-upload-target">
							<i class="far fa-image fa-lg text-light hover:text-normal"></i>
						</a>
					</div>

					<div class="icon-wrapper" data-tooltip="" title="Přidat odkaz nebo video">
						<a class="pointer icon-list-wrapper" @click="insertUrl('Zadejte odkaz nebo video')">
							<i class="fas fa-globe fa-lg text-light hover:text-normal"></i>
						</a>
					</div>

				</div>

				<!-- Submit -->
				<div>
					<button class="button button-color_primary" @click="onSubmit">
						<span>{{template.statusCaption.join(' ')}}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss">
	.status-editor-vue-textarea {
		outline: 0;
		border: 0;
		width: 100%;
		padding: 15px 22px;
	}
	.__status-form {
		.text-inline-subject {
			border: 0;
			outline: 0;
			margin: 10px 0 10px 0;
			font-size: 1.5rem;
			padding: 0 23px;
			color: #000;
		}
		.ck-content {
			min-height: 72px;
			border: 0 !important;
			padding: 0 23px !important;
			box-shadow: none !important;

			@media (max-width: 800px) {
				min-height: 30px;
			}
		}
		.ck-editor__top {
			display: none;
		}
	}
</style>
<script>
	import Options from 'modules/options';
	import regexUrl from 'url-regex';
	import ClickOutside from 'vue-click-outside';
	import PromiseFileReaders from 'modules/reader/PromiseFileReaders';
	import * as imageUtils from 'modules/images/utils';
	import createToast from "@module/components/toast";
	import { createEmbed } from "@module/embed";
	import { createMentionOptions } from "@module/editor/mentions";
	import apiRequest from "@module/api/request";
	import netteAjax from "@module/ajax/nette";

	function createStatusEditor(element, mentionUrl, callback) {
		if (window.WarengoEditor === undefined) {
			throw new Error('Assets for status editor not found');
		}

		window.WarengoEditor.status(element, {
			mention: createMentionOptions(mentionUrl),
		})
			.then(editor => callback(editor))
			.catch(error => {
				throw new Error(error)
			});
	}

	export default {

		data() {
			return {
				User: Options.user,
				hashTags: [],
				embedLink: null,
				editor: null,
				value: '',
				images: [],
				processed: [],
				focus: false,
				focusForced: false,
				subject: null,
				template: {
					statusCaption: ['Přidat', 'status'],
					placeholder: 'Sdílejte své myšlenky...',
					showStoryTab: true,
					subject: false,
				},
				options: {
					type: 'create',
					storyUrl: null,
					storyTab: true,
					url: null,
					redrawLink: null,
					groupId: null,
					mentionUrl: null,
					proposalId: null,
					values: {
						id: null,
						content: null,
						linkMetadata: null,
						video: null,
					},
				}
			};
		},

		computed: {
			visible() {
				if (this.options.type === 'edit') {
					return true;
				}

				return this.focus || this.focusForced;
			}
		},

		methods: {
			hide() {
				this.focus = false;
			},
			reset() {
				this.images = [];
				this.subject = null;
				this.embedLink = null;
				this.editor.setData('');
			},
			createEmbededLink(url, force = false) {
				if (!url) {
					return;
				}
				if (this.images.length) {
					if (!force) {
						return;
					}

					this.images = [];
				}

				this.embedLink = url;

				const link = document.createElement('a');
				link.href = this.embedLink;

				this.$refs.embedLinkContainer.innerHTML = '';
				this.$refs.embedLinkContainer.appendChild(link);

				createEmbed(link);
			},
			async onSubmit(e) {
				if (this.template.subject && !this.subject) {
					alert('Nadpis musí být vyplněn');

					this.$refs.subject.focus();

					return;
				}

				let formData = new FormData();
				formData.set('content', this.editor.getData());
				formData.set('group', this.options.groupId ?? '');
				formData.set('proposal', this.options.proposalId ?? '');
				if (this.template.subject && this.subject) {
					formData.set('subject', this.subject);
				}

				if (this.images.length) {
					this.images.forEach(({file}) => {
						formData.append('images[]', file, file.name);
					});
				} else if (this.embedLink) {
					formData.set('embedLink', this.embedLink);
				}

				let action = 'create';
				if (this.options.type === 'edit') {
					action = `edit/${this.options.values.id}`;
				}

				const result = await apiRequest.processErrorsAndReturnData(
					apiRequest.sendFormData(`status/${action}`, formData)
				);

				if (result !== undefined) {
					if (this.options.type === 'create') {
						createToast(null, 'Status přidán');

						this.reset();
					} else {
						createToast(null, 'Status byl upraven');
					}

					if (this.options.redrawLink) {
						netteAjax.makeRequest('GET', this.options.redrawLink);
					}
				}
			},
			async onUpload(e) {
				const upload = this.$refs.upload;
				const files = imageUtils.checkUploadFiles(upload.files);

				if (!files || !files.length) {
					return;
				}

				const reader = new PromiseFileReaders(files);
				const results = await reader.readAsDataUrl((result, file) => {
					return {
						content: result,
						file,
					};
				});

				this.images = this.images.concat(results);

				if (this.embedLink) {
					this.embedLink = null;
				}

				upload.value = '';
			},
			insertUrl(name) {
				const url = prompt(name);
				if (url === null) {
					return;
				}

				if (!url.match(regexUrl())) {
					alert('Zadaná url není validní');

					return;
				}

				if (this.images.length) {
					if (!confirm('Vložením linku se zruší galerie, opravdu pokračovat?')) {
						return;
					}
				}
				this.createEmbededLink(url, true);
			},
			removeImage(key) {
				this.images.splice(key, 1);
			}
		},

		beforeMount() {
			const options = this.options;

			this.template.statusCaption[0] = 'Přidat';
			if (options.type === 'edit') {
				this.template.statusCaption[0] = 'Upravit';
			}

			const post = options.proposalId || options.groupId;

			this.template.statusCaption[1] = post ? 'příspěvek' : 'status';

			this.template.showStoryTab = !post;

			if (options.groupId) {
				this.template.placeholder = 'Chci diskutovat o...';
			} else if (options.proposalId) {
				this.template.placeholder = 'Zeptejte se na cokoliv...';
			}

			this.template.subject = !!options.groupId;
		},

		mounted() {
			createStatusEditor(this.$refs.content, this.options.mentionUrl, (editor) => {
				this.editor = editor;

				editor.plugins.get('PlainText').on('insert', (evt, [content]) => {
					let urls = content.match(regexUrl());
					if (!urls) {
						return;
					}

					this.createEmbededLink(urls[0]);
				});
				editor.model.document.on('change:data', () => {
					this.focusForced = !!editor.getData();
				});
				editor.ui.focusTracker.on('change:isFocused', (evt, name, value) => {
					if (value === true) {
						this.focus = value;
					}
				});
			});

			this.createEmbededLink(this.options.values.embedLink);
		},

		/**
		 * @param self
		 * @param {HTMLElement} el
		 */
		startup(el) {
			const variable = el.dataset.variable;
			if (!variable) {
				throw new Error('[data-variable] is required')
			}

			const values = window[variable];

			this.options = Object.assign(this.options, values);

			if (this.options.content) {
				this.options.values.content = this.options.content;
			}
			this.value = this.options.values.content;
			if (!this.value) {
				this.value = '';
			}
		},

		directives: {
			ClickOutside,
		}

	}

</script>

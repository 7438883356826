import { Controller } from 'stimulus';
import { useHover } from "stimulus-use";
import Popover from './utils/Popover';

const htmlCache = {};
const popover = new Popover(['js-stimulus-popover', '__vue-popup'], {
	placement: 'top',
	modifiers: [
		{
			name: 'flip',
			options: {
				fallbackPlacements: ['bottom'],
			}
		},
		{
			name: 'offset',
			options: {
				offset: [0, 10],
			}
		}
	]
});
let counter = 0;

export default class extends Controller {
	static values = {
		active: Boolean,
		link: String,
	};

	#id;

	connect() {
		this.#id = counter++;
		useHover(this, {
			element: this.element,
		});
	}

	mouseEnter() {
		this.activeValue = true;
	}

	async activeValueChanged() {
		let cached = htmlCache.hasOwnProperty(this.linkValue) ? htmlCache[this.linkValue] : undefined;
		if (cached === undefined) {
			if (!this.activeValue) {
				return;
			}

			const response = await fetch(this.linkValue);

			if (response.ok) {
				htmlCache[this.linkValue] = cached = await response.text();
			} else if (response.status === 404) {
				cached = '';
			}
		}

		if (!cached) {
			return;
		}

		if (this.activeValue) {
			popover.activate(this.element, cached, this.#id);
		} else {
			popover.deactivate(this.#id);
		}
	}

	mouseLeave() {
		this.activeValue = false;
	}

}

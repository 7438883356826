import {lory} from 'lory.js';

document.addEventListener('DOMContentLoaded', () => {
	const sliderToScroll = window.outerWidth > 700 ? 3 : 1;
	const enableMouseEvents = window.outerWidth < 1000;

	document.querySelectorAll('.js-slider').forEach(el => {
		el.addEventListener('after.lory.init', event => {
			const slidesWidth = el.querySelector('.js_slides').clientWidth;
			const frameWidth = el.querySelector('.js_frame').clientWidth;

			if (slidesWidth <= frameWidth) {
				el.querySelector('.js_prev').style.display = 'none';
				el.querySelector('.js_next').style.display = 'none';
			}

			el.querySelector('.js_prev').classList.add('hidden');
		});
		el.addEventListener('after.lory.slide', event => {
			if (event.detail.currentSlide !== 0) {
				el.querySelector('.js_prev').classList.remove('hidden');
			} else {
				el.querySelector('.js_prev').classList.add('hidden');
			}
		});

		lory(el, {
			rewind: true,
			sliderToScroll,
			enableMouseEvents,
		});
	});
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "score-container __score hover-effect" },
    [
      _vm._l([1, 2, 3, 4, 5], function(i) {
        return _c("span", { staticClass: "score-handler" }, [
          _c("i", {
            class: ["fa-star", _vm.selected >= i ? "fas" : "far"],
            on: {
              click: function($event) {
                _vm.selected = i
              }
            }
          })
        ])
      }),
      _vm._v(" "),
      _c("span", [_vm._v("\n\t\t(" + _vm._s(_vm.selectedText) + ")\n\t")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Controller } from 'stimulus';
import InMemoryCache from "@module/cache/in-memory";

const cache = new InMemoryCache();

export default class extends Controller {
	static values = {
		link: String,
		stock: String,
	};
	static targets = ['graph', 'loading'];

	async connect() {
		this.loadGraph(
			await cache.fallback(this.stockValue, () => this.#fetch())
		);
	}

	async #fetch() {
		const response = await fetch(this.linkValue);
		if (response.ok) {
			const data = await response.text();
			return JSON.parse(data);
		}
		if (response.status === 404) {
			return '';
		}

		return undefined;
	}

	loadGraph(data) {
		if (typeof data !== 'object') {
			this.loadingTarget.innerHTML = 'Graf se nepodařilo načíst';

			return;
		}

		new Chart(this.graphTarget.getContext('2d'), {
			type: 'line',
			data: {
				labels: data.labels,
				datasets: [{
					backgroundColor: 'rgba(67, 206, 175, .5)',
					borderColor: 'rgb(67, 206, 175)',
					data: data.values,
				}],
			},
			options: {
				legend: {
					display: false
				},
			},
		});

		this.loadingTarget.classList.add('hidden');
		this.graphTarget.classList.remove('hidden');
	}

}

import { bodyScroller } from "@module/dom/body-scroller";

document.querySelectorAll('.note-button').forEach(el => {
	const container = el.closest('.__note');
	const wrapper = container.querySelector('.note-view-wrapper');
	el.addEventListener('click', () => {
		el.classList.toggle('active');
		wrapper.classList.toggle('active');

		bodyScroller(wrapper.classList.contains('active'));
	});
});

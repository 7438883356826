import './scss/body-scroller.scss';

/**
 * @param {boolean} disabled
 */
export function bodyScroller(disabled = true) {
	document.querySelectorAll('body,html').forEach(el => {
		if (disabled) {
			el.classList.add('js-noscroll');
		} else {
			el.classList.remove('js-noscroll');
		}
	});
}


export default class DebugExtension {

	constructor(naja) {
		naja.addEventListener('error', (args) => {
			console.error(args.error);
		});
	}

}

import factory from '@webchemistry/toast-component';
import '@webchemistry/toast-component/asset/toast.css';

const translations = {
	error: 'Chyba',
	warning: 'Varování',
	info: 'Info',
	success: 'Úspěch',
};

/**
 * @param {string|null} title
 * @param {string} content
 * @param {string} type
 */
export default function createToast(title, content, type = 'success') {
	if (title === null) {
		title = translations[type] ?? type.charAt(0).toUpperCase() + type.substr(1);
	}

	factory(title, content, type);
}
